import React from 'react';
import styled from 'styled-components/macro';
import { Link } from 'gatsby';

import { black } from '../utils/sharedStyles';

const Wrapper = styled.div`
  margin-top: 7rem;
  padding-bottom: 1.5rem;
`;

const FixedWrapper = styled.div`
  position: absolute;
  bottom: 1.5rem;
  left: 0;
  right: 0;
  text-align: center;
`;

const Element = styled.span`
  font: normal normal normal 12px/14px Helvetica Neue;
  opacity: 0.5;
  border-right: ${({ isLast }) => (isLast ? 'none' : `1px solid ${black}`)};
  padding: 0 1rem;
`;

const StyledLink = styled(Link)`
  text-decoration: none;
  color: ${black};
  margin: 1rem 0;
`;

export const Footer = () => (
  <Wrapper>
    <FixedWrapper>
      <Element>&#169; Ekout Studio {new Date().getFullYear()}</Element>
      <Element>
        <StyledLink to="/imprint">Imprint/Impressum</StyledLink>
      </Element>
      <Element>
        <StyledLink to="/privacy-policy">Privacy Policy</StyledLink>
      </Element>
      <Element isLast>
        <StyledLink to="/contact">Contact</StyledLink>
      </Element>
    </FixedWrapper>
  </Wrapper>
);
