import React, { useState } from 'react';
import styled from 'styled-components';

import { black, white } from '../../utils/sharedStyles';

const ProgressBarContainer = styled.div`
  flex-grow: 1;
  display: flex;
  align-items: center;
  margin-left: 0.3rem;
  cursor: pointer;
`;

const Bar = styled.div`
  position: relative;
  background-color: ${white};
  height: 3px;
  flex-grow: 1;
`;

const BarHandle = styled.div`
  width: 9px;
  height: 9px;
  border-radius: 10px;
  background-color: ${black};
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
`;

export const ProgressBar = React.forwardRef(
  (
    {
      playAudio,
      pauseAudio,
      positionHandle,
      setPlaybackPosition,
      setIsPlaying,
    },
    ref
  ) => {
    const [isDragging, setIsDragging] = useState(false);
    const { barRef, handleRef } = ref;

    const getPlaybackRatio = e => {
      const mousePosition =
        e.pageX - barRef.current.getBoundingClientRect().left;
      const ratio = mousePosition / barRef.current.offsetWidth;
      return ratio;
    };

    const onBarDown = e => {
      pauseAudio();
      const ratio = getPlaybackRatio(e);
      positionHandle(ratio);
      setIsDragging(true);
      setIsPlaying(false);
    };

    const onBarUp = e => {
      playAudio();
      const ratio = getPlaybackRatio(e);
      positionHandle(ratio);
      setPlaybackPosition(ratio);
      setIsDragging(false);
      setIsPlaying(true);
    };

    const onBarDrag = e => {
      if (isDragging) {
        const ratio = getPlaybackRatio(e);
        if (ratio < 0 || ratio > 1) setIsDragging(false);
        setPlaybackPosition(ratio);
        positionHandle(ratio);
      }
    };

    return (
      <ProgressBarContainer
        ref={barRef}
        onMouseDown={onBarDown}
        onMouseUp={onBarUp}
        onMouseMove={onBarDrag}
        onTouchMove={onBarDrag}
      >
        <Bar>
          <BarHandle ref={handleRef} onDrag={onBarDrag} />
        </Bar>
      </ProgressBarContainer>
    );
  }
);

ProgressBar.displayName = 'ProgressBar';
