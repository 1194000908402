import React from 'react';
import styled from 'styled-components';

const Paragraph = styled.p`
  font: normal normal 300 20px/28px Heebo;
  margin-bottom: 0;
  ${({ styles }) => styles}
`;

export const Text = ({ children, styles, ...props }) => (
  <Paragraph styles={styles} {...props}>
    {children}
  </Paragraph>
);
