import React from 'react';
import styled from 'styled-components';

const StyledHeadline = styled.h2`
  font: normal normal ${({ semiBold }) => (semiBold ? 'normal' : 'bold')} 36px
    Ibarra Real Nova;
  margin-bottom: 1rem;
`;

export const Headline = ({ children, semiBold }) => {
  return <StyledHeadline semiBold={semiBold}>{children}</StyledHeadline>;
};
