import React, { useState } from 'react';
import styled, { css } from 'styled-components/macro';
import { Box, Grid, Switch } from '@material-ui/core';

import { PlayerControls } from './PlayerControls';

import {
  borderRadius,
  lightBlack,
  mediumGrey,
  lightGrey,
  transition,
  white,
} from '../../utils/sharedStyles';

const PlayerWrapper = styled(Box)`
  background-color: ${mediumGrey};
  max-width: 100%;
  min-width: 300px;
  margin: 1rem;
  ${borderRadius}
`;

const Container = styled.div`
  padding: 12px;
`;

const Cover = styled.div`
  width: 100%;
  height: 99px;
  min-height: 0;
  background: url('${props => props.source}') center/cover;
  box-shadow: 1px 1px 4px grey;
  ${borderRadius}
`;

const Artist = styled.p`
  font: normal normal 600 20px/22px Heebo;
  margin-bottom: 0.3rem;
`;

const Title = styled.p`
  font: normal normal 300 16px/22px Heebo;
`;

const SwitchWrapper = styled(Grid)`
  position: relative;
`;

const SwitchButton = styled(Switch)`
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
`;

const activeStyles = css`
  background-color: ${lightGrey};
  color: ${lightBlack};
`;

const SwitchItem = styled.div`
  font: normal normal 800 16px Heebo;
  text-align: center;
  padding: 2rem;
  ${({ border }) => border && `border-right: 1px solid ${white};`}
  ${transition}
  background-color: #edebe4;
  color: #aaa6a0;
  ${({ isActive }) => isActive && activeStyles}
`;

const before = 'before';
const after = 'after';

export const Player = ({ item }) => {
  const [isActive, setIsActive] = useState(before);
  const isBefore = isActive === before;
  const isAfter = isActive === after;

  const onSwitch = () => {
    if (isBefore) setIsActive(after);
    else setIsActive(before);
  };

  return (
    <PlayerWrapper key={item.id}>
      <Container>
        <Grid container spacing={2} p={5}>
          <Grid item xs={5}>
            <Cover source={item.cover} />
          </Grid>
          <Grid item xs={7}>
            <Artist>{item.artist}</Artist>
            <Title>{item.title}</Title>
          </Grid>
          <Grid container alignItems="center" item xs={12}>
            <PlayerControls
              afterAudio={item.after}
              beforeAudio={item.before}
              isAfter={isAfter}
              isBefore={isBefore}
            />
          </Grid>
        </Grid>
      </Container>
      {item.after && (
        <SwitchWrapper container>
          <Grid item xs={6}>
            <SwitchItem border isActive={isBefore}>
              BEFORE
            </SwitchItem>
          </Grid>
          <SwitchButton color="default" onChange={onSwitch} />
          <Grid item xs={6}>
            <SwitchItem isActive={isAfter}>AFTER</SwitchItem>
          </Grid>
        </SwitchWrapper>
      )}
    </PlayerWrapper>
  );
};
