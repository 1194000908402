import React, { useEffect, useState, useRef } from 'react';
import styled, { css } from 'styled-components/macro';
import {
  PauseCircleFilledRounded,
  PlayCircleFilledRounded,
} from '@material-ui/icons';

import { ProgressBar } from './ProgressBar';

import { black } from '../../utils/sharedStyles';

const PlayerWrapper = styled.div`
  position: relative;
  width: 100%;
  display: flex;
  align-items: stretch;
`;

const IconWrapper = styled.span`
  display: inline-flex;
  position: relative;
`;

const iconStyles = css`
  z-index: 1;
  cursor: pointer;
`;

const PlayIcon = styled(PlayCircleFilledRounded)`
  ${iconStyles}
`;
const PauseIcon = styled(PauseCircleFilledRounded)`
  ${iconStyles}
`;

const IconBackground = styled.span`
  background-color: ${black};
  height: 20px;
  width: 20px;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  z-index: 0;
`;

const ProgressTime = styled.span`
  position: absolute;
  right: 0;
  top: -10px;
  font: normal normal normal 12px/18px Heebo;
  cursor: pointer;
`;

export const PlayerControls = ({
  isBefore,
  isAfter,
  beforeAudio,
  afterAudio,
}) => {
  const [isPlaying, setIsPlaying] = useState(false);
  const [currentTime, setCurrentTime] = useState();

  const handleRef = useRef(null);
  const barRef = useRef(null);

  const beforeAudioRef = useRef(null);
  const afterAudioRef = useRef(null);
  const refs = [beforeAudioRef, afterAudioRef];

  const pauseAudio = () =>
    refs.forEach(ref => ref && ref.current && ref.current.pause());

  const playAudio = () => {
    if (afterAudioRef && afterAudioRef.current && isAfter)
      afterAudioRef.current.play();
    else beforeAudioRef.current.play();
  };

  useEffect(() => {
    if (isPlaying) {
      if (isBefore) {
        afterAudioRef.current.pause();
        beforeAudioRef.current.play();
        beforeAudioRef.current.currentTime = afterAudioRef.current.currentTime;
      } else {
        beforeAudioRef.current.pause();
        afterAudioRef.current.play();
        afterAudioRef.current.currentTime = beforeAudioRef.current.currentTime;
      }
    }
  }, [isBefore, isAfter]);

  const positionHandle = ratio => {
    const progressBarWidth = barRef.current.offsetWidth;
    const position = progressBarWidth * ratio;

    if (position >= 0 && position <= progressBarWidth)
      handleRef.current.style.marginLeft = `${position}px`;

    if (position < 0) handleRef.current.style.marginLeft = 0;

    if (position > progressBarWidth)
      handleRef.current.style.marginLeft = `${progressBarWidth}px`;
  };

  const handleTimer = () => {
    const audioDuration = beforeAudioRef.current.paused
      ? afterAudioRef.current.currentTime
      : beforeAudioRef.current.currentTime;
    const minutes = Math.floor(audioDuration / 60);
    let seconds = Math.floor(audioDuration - minutes * 60);

    if (seconds.toString().length === 1) seconds = `0${seconds}`;
    setCurrentTime({ minutes, seconds });
  };

  const setPlaybackPosition = ratio => {
    refs
      .filter(ref => ref.current)
      .map(({ current }) => {
        // eslint-disable-next-line no-param-reassign
        current.currentTime = current.duration * ratio;
      });
  };

  useEffect(() => {
    const before = beforeAudioRef.current;
    const after = afterAudioRef && afterAudioRef.current;

    const updateProgressBefore = () => {
      const ratio = before.currentTime / before.duration;
      positionHandle(ratio);
      handleTimer();
    };

    const updateProgressAfter = () => {
      const ratio = after.currentTime / after.duration;
      positionHandle(ratio);
      handleTimer();
    };

    before.addEventListener('timeupdate', updateProgressBefore);
    if (afterAudio) after.addEventListener('timeupdate', updateProgressAfter);
    return () => {
      before.removeEventListener('timeupdate', updateProgressBefore);
      if (afterAudio)
        after.removeEventListener('timeupdate', updateProgressAfter);
    };
  }, []);

  const onControlClick = () => {
    if (isPlaying) pauseAudio();
    else playAudio();
    setIsPlaying(!isPlaying);
  };

  const onEnded = () => {
    setIsPlaying(false);
    handleRef.current.style.marginLeft = 0;
  };

  const ControlIcon = isPlaying ? PauseIcon : PlayIcon;

  return (
    <PlayerWrapper>
      <IconWrapper>
        <ControlIcon
          onClick={onControlClick}
          htmlColor="white"
          fontSize="large"
        />
        <IconBackground />
      </IconWrapper>
      <ProgressTime>
        {currentTime && (
          <>
            {currentTime.minutes}:{currentTime.seconds}
          </>
        )}
      </ProgressTime>
      <ProgressBar
        playAudio={playAudio}
        pauseAudio={pauseAudio}
        positionHandle={positionHandle}
        setPlaybackPosition={setPlaybackPosition}
        setIsPlaying={setIsPlaying}
        ref={{
          handleRef,
          barRef,
        }}
      />
      <audio ref={beforeAudioRef} onEnded={onEnded}>
        <source src={beforeAudio} type="audio/mpeg" />
        Your browser does not support the audio element.
      </audio>
      {afterAudio && (
        <audio ref={afterAudioRef} onEnded={onEnded}>
          <source src={afterAudio} type="audio/mpeg" />
          Your browser does not support the audio element.
        </audio>
      )}
    </PlayerWrapper>
  );
};
