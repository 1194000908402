import React, {
  useState,
  useRef,
  createRef,
  forwardRef,
  useEffect,
} from 'react';
import styled, { css } from 'styled-components/macro';

import {
  beige,
  black,
  borderRadius,
  borderRadiusBottom,
  borderRadiusTop,
  mediumGrey,
  lightSalmon,
  lightGrey,
  salmon,
  white,
} from '../utils/sharedStyles';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
`;

const Headline = styled.div`
  background-color: ${mediumGrey};
  font: normal normal 600 15px/21px Heebo;
  ${borderRadiusTop}
`;

const Item = styled.div`
  background-color: ${({ isActive }) => (isActive ? lightSalmon : lightGrey)};
  height: 40px;
  transition: 0.1s;
  cursor: pointer;
  font: normal normal ${({ isActive }) => (isActive ? '600' : '300')} 13px/21px
    Heebo;

  &:last-of-type {
    ${borderRadiusBottom}
  }

  &:hover {
    background-color: ${lightSalmon};
  }
`;

const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0 1rem;
  height: 100%;
  min-height: 40px;
`;

const BorderContainer = styled.div`
  background-color: ${lightGrey};
`;

const Border = styled.div`
  border-bottom: 1px dotted ${black};
  margin: 0 1rem;
`;

const Box = styled.div`
  align-self: start;
  width: 100%;
`;

const activeInfoStyles = css`
  overflow: visible;
  color: ${white};
  background-color: ${salmon};
  min-width: 100%;
  min-height: 100%;
  max-height: 100%;
  top: 0;
  right: 0;
  ${borderRadius}
`;

const Info = styled.div`
  visibility: visible;
  z-index: ${({ number }) => number};
  background-color: white;
  max-height: 26px;
  min-height: 26px;
  min-width: 26px;
  width: 26px;
  border-radius: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  cursor: pointer;
  overflow: hidden;
  position: absolute;
  right: 7px;
  top: 7px;
  background-color: ${beige};
  transition: all 0.6s ease-in-out;

  &:hover {
    color: ${white};
    background-color: ${salmon};
  }

  ${({ isActive }) => isActive && activeInfoStyles}
`;

const activeContent = css`
  visibility: visible;
  z-index: 100;
  opacity: 1;
  transition: all 0.6s ease-in-out;
  transition-delay: 0.4s;
`;

const InfoContent = styled.div`
  cursor: pointer;
  position: absolute;
  top: 4.5rem;
  width: 100%;
  ${borderRadius}
  color: ${white};
  opacity: 0;
  font: normal normal 600 16px/21px Heebo;
  padding: 0 2rem 2rem;
  transition: all 0.2s;

  ${({ isActive }) => isActive && activeContent}
`;

const activeHeadline = css`
  margin-top: 1rem;
`;

const InfoHeadline = styled.div`
  width: 80%;
  text-align: center;
  padding-bottom: 1rem;
  margin-top: 0.2rem;
  margin-bottom: 1rem;
  border-bottom: 1px solid ${white};
  transition: width 0.1s ease-in-out;
  transition: margin 0.6s ease-in-out;

  &::before {
    content: 'i';
  }

  ${({ isActive }) => isActive && activeHeadline}
`;

const InfoWrapper = styled.div`
  position: absolute;
  width: 100%;
  min-height: ${({ height }) => `${height}px`};
  top: 0;
  right: 0;
  visibility: ${({ isActive }) => (isActive ? 'visible' : 'hidden')};
`;

const CalculatorBox = forwardRef(({ box, onItemClick }, ref) => {
  const { items, headline, info, number } = box;
  const [activeItem, setActiveItem] = useState(items[0].id);
  const [activeInfo, setActiveInfo] = useState(false);
  const [currentPrice, setCurrentPrice] = useState(items[0].price);

  const contentRef = useRef(null);

  useEffect(() => {
    onItemClick({
      price: items[0].price,
      chosenOption: {
        headline,
        item: { name: items[0].name, id: items[0].id },
      },
    });
  }, []);

  const getInfoHeight = () => {
    const containerHeight = ref && ref.current && ref.current.offsetHeight;
    const infoHeight =
      contentRef && contentRef.current && contentRef.current.offsetHeight + 64;

    if (containerHeight > infoHeight) return containerHeight;
    return infoHeight;
  };

  const height = getInfoHeight();

  const numberOfItems = items.length;

  return (
    <Container ref={ref}>
      <Headline>
        <Wrapper>
          {headline}
          <InfoWrapper isActive={activeInfo} height={height}>
            <Info
              number={number}
              isActive={activeInfo}
              onClick={() => setActiveInfo(active => !active)}
            >
              <InfoHeadline isActive={activeInfo} />
            </Info>
            <InfoContent
              onClick={() => setActiveInfo(false)}
              ref={contentRef}
              isActive={activeInfo}
            >
              {info}
            </InfoContent>
          </InfoWrapper>
        </Wrapper>
      </Headline>
      {items.map((item, index) => {
        const { id, name, price } = item;
        const isLastItem = index === numberOfItems - 1;
        return (
          <React.Fragment key={id}>
            <Item
              isActive={activeItem === id}
              onClick={() => {
                setActiveItem(id);
                setCurrentPrice(lastPrice => {
                  onItemClick({
                    price: price - lastPrice,
                    chosenOption: { headline, item: { name, id } },
                  });
                  return price;
                });
              }}
            >
              <Wrapper border={index !== numberOfItems - 1}>
                <span>{name}</span>
                {!!price && <span>{price}&#8364;</span>}
              </Wrapper>
            </Item>
            {!isLastItem && (
              <BorderContainer>
                <Border />
              </BorderContainer>
            )}
          </React.Fragment>
        );
      })}
    </Container>
  );
});

CalculatorBox.displayName = 'CalculatorBox';

export const CalculatorBoxes = ({ boxes, onItemClick }) => {
  const refs = useRef(boxes.map(() => createRef()));

  return (
    <>
      {boxes.map((box, index) => (
        <Box key={box.id}>
          <CalculatorBox
            ref={refs.current[index]}
            box={box}
            onItemClick={onItemClick}
          />
        </Box>
      ))}
    </>
  );
};
