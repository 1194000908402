import React from 'react';
import styled, { keyframes } from 'styled-components/macro';

export const LoadingSpinner = () => (
  <Container>
    <Wrapper>
      <div />
      <div />
      <div />
      <div />
    </Wrapper>
  </Container>
);

const Container = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
`;

const ellipsis1 = keyframes`
  0% {
    transform: scale(0);
  }
  100% {
    transform: scale(1);
  }
`;
const ellipsis2 = keyframes`
  0% {
    transform: translate(0, 0);
  }
  100% {
    transform: translate(12px, 0);
`;
const ellipsis3 = keyframes`
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(0);
  }
`;

const Wrapper = styled.div`
  display: inline-block;
  position: relative;
  width: 40px;
  height: 40px;

  & div {
    position: absolute;
    top: 16.5px;
    width: 7.5px;
    height: 7.5px;
    border-radius: 50%;
    background: #fff;
    animation-timing-function: cubic-bezier(0, 1, 1, 0);
    background: black;
  }

  & div:nth-child(1) {
    left: 4px;
    animation: ${ellipsis1} 0.6s infinite;
  }

  & div:nth-child(2) {
    left: 4px;
    animation: ${ellipsis2} 0.6s infinite;
  }

  & div:nth-child(3) {
    left: 16px;
    animation: ${ellipsis2} 0.6s infinite;
  }

  & div:nth-child(4) {
    left: 28px;
    animation: ${ellipsis3} 0.6s infinite;
  }
`;
