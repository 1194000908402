import React from 'react';
import styled from 'styled-components/macro';

const StyledList = styled.ul`
  list-style-position: inside;
`;

const StyledLi = styled.li`
  padding-bottom: 0.5rem;
  padding-left: 2em;
  text-indent: -1.5em;
`;

const Item = styled.span`
  font: normal normal 300 ${({ fontSize }) => fontSize || '20px/28px'} Heebo;
  ${({ font }) => font && font}
`;

const ItemBold = styled(Item)`
  font: normal normal normal 20px/28px Heebo;
`;

const ItemType = styled(Item)`
  &::before {
    content: ' ';
  }
`;

export const List = ({ items, fontSize, font }) => (
  <StyledList>
    {items.map((item, index) => (
      <StyledLi key={item.id ? item.id : index}>
        {item instanceof Object ? (
          <Item>
            <ItemBold>{item.name}</ItemBold>
            {item.type && <ItemType>({item.type})</ItemType>}
          </Item>
        ) : (
          <Item
            fontSize={fontSize}
            font={font}
            dangerouslySetInnerHTML={{ __html: item }}
          />
        )}
      </StyledLi>
    ))}
  </StyledList>
);
