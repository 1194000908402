import React, { useState, useRef } from 'react';
import styled, { css } from 'styled-components/macro';
import { Formik, Field, Form, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import { Divider, Grid, Popover } from '@material-ui/core';
import { toast } from 'react-toastify';
import emailjs from 'emailjs-com';

import { LoadingSpinner } from './LoadingSpinner';

import {
  beige,
  black,
  borderRadiusBottom,
  borderRadius,
  mediumGrey,
  lightGrey,
  salmon,
  white,
} from '../utils/sharedStyles';
import { scrollToElement } from '../utils/helpers';

const cssTransitionDuration = 600;

const padding = css`
  padding: 14px 10px;
`;

const margin = css`
  margin: 0.5rem 1rem;
`;

const spacing = css`
  max-width: calc(100% - 2rem);
  ${margin}
  ${padding}
`;

const Wrapper = styled.div`
  background-color: ${lightGrey};
`;

const Input = styled.input`
  width: 100%;
  border: none;
  font: normal normal 300 16px Heebo;
  background-color: ${beige};
  ${spacing};
`;

const TextArea = styled.textarea`
  flex-grow: 1;
  border: none;
  font: normal normal 300 16px Heebo;
  background-color: ${beige};
  ${spacing};
`;

const OptionsContainer = styled.div`
  border-top: 1px solid ${black};
  ${margin}
`;

const OptionsHeadline = styled.h3`
  font: normal normal 600 12px/18px Heebo;
  padding: 0.5rem 0;
`;

const OptionHeadline = styled.span`
  font: normal normal normal 12px/18px Heebo;
  &::after {
    content: ': ';
  }
`;

const optionsNameContent = css`
  &::after {
    color: ${black};
    content: ' // ';
  }
`;

const OptionName = styled.span`
  font: normal normal bold 12px/18px Heebo;

  color: ${salmon};
  ${props => !props.isLast && optionsNameContent};
`;

const buttonHeight = css`
  height: 52px;
`;

const Button = styled.button`
  cursor: pointer;
  width: 100%;
  border: none;
  ${buttonHeight}
`;

const DemoButtonContainer = styled.div`
  ${margin}
`;

const PopOverButton = styled(Button)`
  font-family: Heebo;
  font-weight: 800;
  color: ${black};
  text-transform: uppercase;
  padding: 10px 30px;
  background-color: ${mediumGrey};
`;

const actionButtonFont = css`
  font-family: Heebo;
  font-weight: 800;
  size: 15px;
  color: rgb(77, 70, 66);
`;

const AttachDemoButton = styled(Button)`
  background-color: ${mediumGrey};
  ${actionButtonFont}
`;

const RequestButton = styled(Button)`
  background-color: ${mediumGrey};
  ${actionButtonFont}
  ${borderRadius}
`;

const SubmitContainer = styled.div`
  margin-top: 0.5rem;
  position: relative;
  ${buttonHeight}
`;

const disabledButton = css`
  background-color: ${mediumGrey};
  color: ${white};

  &:hover {
    cursor: auto;
  }
`;

const SubmitButton = styled(Button)`
  background-color: ${salmon};
  position: absolute;
  bottom: 0;
  ${actionButtonFont}
  ${borderRadiusBottom}
color: ${white};

  ${({ disabled }) => disabled && disabledButton}
`;

const openForm = css`
  padding-top: 0.5rem;
  max-height: 1000px;
`;

const closedForm = css`
  padding-top: 0;
  max-height: 0;
`;

const FormTransition = styled.div`
  ${props => (props.isOpen ? openForm : closedForm)}
  overflow: hidden;
  transition: all ${cssTransitionDuration / 1000}s;
`;

const Error = styled.p`
  color: red;
  margin: 0 1rem 0.5rem;
`;

const ContactSchema = Yup.object().shape({
  firstName: Yup.string().required('Required'),
  lastName: Yup.string().required('Required'),
  email: Yup.string().email('Invalid email').required('Required'),
  message: Yup.string().required('Required'),
});

export const ContactForm = ({ chosenOptions }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [isSending, setIsSending] = useState(false);
  const formRef = useRef(null);

  const toggleFormHandler = () => {
    if (!isOpen) {
      setIsOpen(true);
      setTimeout(() => scrollToElement({ element: formRef.current }), 600);
    }
    setIsOpen(!isOpen);
  };

  const onSubmit = event => {
    event.preventDefault();
    setIsSending(true);
    emailjs
      .sendForm(
        process.env.GATSBY_EMAIL_SERVICE_ID,
        process.env.GATSBY_EMAIL_TEMPLATE_ID,
        event.target,
        process.env.GATSBY_EMAIL_USER_ID
      )
      .then(
        () => {
          setIsOpen(false);
          toast('Email sent!');
          setTimeout(() => {
            setIsSending(false);
          }, cssTransitionDuration);
        },
        () => {
          setIsSending(false);
          toast('Email could not be sent. Try again.');
        }
      );
  };

  return (
    <Wrapper ref={formRef} isOpen={isOpen}>
      <RequestButton onClick={toggleFormHandler}>SEND REQUEST</RequestButton>
      <FormTransition isOpen={isOpen}>
        <Formik
          initialValues={{
            firstName: '',
            lastName: '',
            email: '',
            phone: '',
            link: '',
            subject: '',
            message: '',
          }}
          validateOnChange
          validationSchema={ContactSchema}
        >
          {({ errors, handleChange, setFieldTouched, touched }) => (
            <Form onSubmit={onSubmit} name="contact">
              <Grid container>
                <Grid item xs={12} sm={4} mx={2}>
                  <Field
                    as={Input}
                    name="firstName"
                    type="text"
                    placeholder="First Name*"
                    onChange={e => {
                      setFieldTouched('firstName');
                      handleChange(e);
                    }}
                  />
                  <ErrorMessage component={Error} name="firstName" />
                  <Field
                    as={Input}
                    name="lastName"
                    type="text"
                    placeholder="Last Name*"
                    onChange={e => {
                      setFieldTouched('lastName');
                      handleChange(e);
                    }}
                  />
                  <ErrorMessage component={Error} name="lastName" />
                  <Field
                    as={Input}
                    name="email"
                    type="email"
                    placeholder="E-Mail*"
                    onChange={e => {
                      setFieldTouched('email');
                      handleChange(e);
                    }}
                  />
                  <ErrorMessage component={Error} name="email" />
                  <Field
                    as={Input}
                    name="phone"
                    type="text"
                    placeholder="Phone"
                  />
                  <AttachDemo />
                </Grid>
                <Grid item container xs={12} sm={8} direction="column">
                  <Field
                    as={Input}
                    name="subject"
                    type="text"
                    placeholder="Subject"
                  />
                  <Field
                    as={TextArea}
                    name="message"
                    type="text"
                    placeholder="Your Message*"
                    onChange={e => {
                      setFieldTouched('message');
                      handleChange(e);
                    }}
                  />
                  <ErrorMessage component={Error} name="message" />
                  {chosenOptions && (
                    <OptionsContainer>
                      <OptionsHeadline>Your Chosen Options</OptionsHeadline>
                      {chosenOptions.map((option, index) => {
                        const isLast = chosenOptions.length - 1 === index;
                        return (
                          <React.Fragment key={option.item.id}>
                            <OptionHeadline>{option.headline}</OptionHeadline>
                            <OptionName isLast={isLast}>
                              {option.item.name}
                              <Field
                                type="hidden"
                                name={`option_${index}`}
                                value={`${option.headline}: ${option.item.name}`}
                              />
                            </OptionName>
                          </React.Fragment>
                        );
                      })}
                    </OptionsContainer>
                  )}
                </Grid>
                <Grid item xs={12} py={0}>
                  <SubmitContainer>
                    <SubmitButton
                      disabled={
                        !(
                          Object.keys(errors).length === 0 &&
                          errors.constructor === Object &&
                          touched.firstName &&
                          touched.lastName &&
                          touched.email &&
                          touched.message
                        ) || isSending
                      }
                      type="submit"
                    >
                      {isSending ? <LoadingSpinner /> : 'SUBMIT'}
                    </SubmitButton>
                  </SubmitContainer>
                </Grid>
              </Grid>
            </Form>
          )}
        </Formik>
      </FormTransition>
    </Wrapper>
  );
};

const AttachDemo = () => {
  const [showInput, setShowInput] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);

  const open = Boolean(anchorEl);

  const handleClick = event => {
    event.preventDefault();
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleShowInput = () => {
    setShowInput(true);
    handleClose();
  };

  return (
    <>
      {showInput ? (
        <Field
          as={Input}
          name="link"
          type="text"
          placeholder="Link To Your Demo"
        />
      ) : (
        <DemoButtonContainer>
          <AttachDemoButton onClick={handleClick}>ATTACH DEMO</AttachDemoButton>
        </DemoButtonContainer>
      )}
      <Popover
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        <PopOverButton onClick={handleShowInput}>Paste Url</PopOverButton>
        <Divider />
        <a href="https://wetransfer.com/" target="_blank" rel="noreferrer">
          <PopOverButton>No link yet? Upload on WeTransfer</PopOverButton>
        </a>
      </Popover>
    </>
  );
};
