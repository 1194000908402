import { useEffect } from 'react';
import { headerHeight } from './sharedStyles';

export const getImageFromArray = ({ images, name }) =>
  images.find(image => image.fluid.originalName === name).fluid;

export const scrollToElement = ({ element, bottom }) => {
  if (typeof window !== 'undefined') {
    const scrollPosition = window.scrollY;
    const relativeElementPosition = element.getBoundingClientRect().y;
    const elementHeight = element.offsetHeight;
    const absoluteElementPosition =
      relativeElementPosition + scrollPosition - headerHeight;
    const cantScrollToElement =
      document.body.clientHeight - absoluteElementPosition + elementHeight <
      window.innerHeight;

    if (cantScrollToElement) {
      setTimeout(() => {
        const scrollToPageBottom =
          document.body.clientHeight - window.innerHeight;
        window.scrollTo({
          top: scrollToPageBottom,
          behavior: 'smooth',
        });
      }, 400);
    } else {
      window.scrollTo({
        top: bottom
          ? absoluteElementPosition + elementHeight
          : absoluteElementPosition,
        behavior: 'smooth',
      });
    }
  }
};

const setViewHeight = () => {
  const vh = window.innerHeight * 0.01;
  document.documentElement.style.setProperty('--vh', `${vh}px`);
};

export const useViewHeight = () => {
  useEffect(() => {
    setViewHeight();

    if (typeof window !== 'undefined') {
      window.addEventListener('resize', setViewHeight);
      return () => {
        window.removeEventListener('resize', setViewHeight);
      };
    }
  }, []);
};
