import React from 'react';
import { Helmet } from 'react-helmet';

import logo from '../assets/images/favicon.jpg';

export const Meta = () => (
  <Helmet>
    <meta charset="utf-8" />
    <meta name="author" content="Ekout Studio" />
    <meta
      name="copyright"
      content={`Copyright Ekout Studio ${new Date().getFullYear()}. All Rights Reserved.`}
    />
    <meta
      name="viewport"
      content="width=device-width, initial-scale=1.0, user-scalable=yes"
    />
    <meta
      name="keywords"
      content="mixing, mastering, music production, composing, vassili ekoutsidis"
    />

    <title>Ekout Studio</title>
    <meta
      name="description"
      content="Ekout Studio is a music studio based in Berlin/Germany. Mixing, Mastering, Music Production &amp; Composing."
    />

    <link rel="icon" type="image/png" sizes="32x32" href={logo} />
    <link rel="icon" type="image/png" sizes="16x16" href={logo} />
    <link rel="apple-touch-icon" sizes="120x120" href={logo} />
    <link rel="apple-touch-icon" sizes="152x152" href={logo} />

    <meta name="apple-mobile-web-app-capable" content="yes" />
    <meta name="apple-mobile-web-app-status-bar-style" content="black" />

    <meta
      property="og:description"
      content="Ekout Studio is a music studio based in Berlin/Germany. Mixing, Mastering, Music Production &amp; Composing."
    />
    <meta property="og:type" content="website" />
    <meta property="og:title" content="Ekout Studio" />
    <meta property="og:url" content="http://www.ekoutstudio.com" />
    <meta property="og:site_name" content="Ekout Studio" />
    <meta property="og:image" content={logo} />
  </Helmet>
);
