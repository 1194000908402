import React, { useEffect, useState } from 'react';
import styled, { keyframes } from 'styled-components/macro';
import { StaticQuery, graphql } from 'gatsby';
import BackgroundImage from 'gatsby-background-image';
import { Box } from '@material-ui/core';
import { ToastContainer } from 'react-toastify';

import { Header } from './Header';
import { Footer } from './Footer';
import { PageTransition, ElementTransition } from './Transitions';

import {
  black,
  contentWidthDesktop,
  darkGrey,
  mediaDesktop,
  mediaMobile,
  salmon,
  white,
} from '../utils/sharedStyles';

const LayoutContainer = styled.div`
  width: 100%;
  max-width: 1920px;
  margin: 0 auto;
  height: 100%;
  min-height: 100vh;
  position: relative;
`;

const ContentWrapper = styled.div`
  width: 100%;
  max-width: ${contentWidthDesktop};
  padding: 1rem;
  margin: 0 auto;
  position: relative;
`;

const IntroTextWrapper = styled.div`
  width: ${contentWidthDesktop};
  max-width: 100vw;
  height: 100%;
  margin: 0 auto;
  padding: 0 2rem;
  display: flex;
  align-items: center;
`;

const TextWrapperDesktop = styled(IntroTextWrapper)`
  @media ${mediaMobile} {
    display: none;
  }
`;

const TextWrapperMobile = styled(IntroTextWrapper)`
  padding: 0;
  width: auto;
  @media ${mediaDesktop} {
    display: none;
  }
`;

const IntroText = styled.h1`
  font: normal normal 900 7rem/7.2rem Heebo;
  opacity: ${({ fullHeight }) => (fullHeight ? '1' : '0')};
  color: #fffff7;
  width: 100%;
  margin: ${({ fullHeight }) => (fullHeight ? '150px' : '0')} 0 4rem;
  transition: all 0.5s;
  ${({ fullHeight }) => fullHeight && 'transition-delay: 0.2s;'};

  @media ${mediaMobile} {
    font: normal normal 900 4rem/4.5rem Heebo;
    color: ${darkGrey};
    margin: 0;
  }
`;

const ImgCarouselSpacer = styled.div`
  height: 10000px;
  max-height: ${({ fullHeight }) => (fullHeight ? '100vh' : '50vh')};
  transition: all 0.7s;
`;

const emphasize = keyframes`
  from {
   color: ${white};
  }
  to {
    color: ${salmon};
  }
`;

const Toastify__trackProgress = keyframes`
  0% {
    transform: scaleX(1);
  }
  100% {
    transform: scaleX(0);
  }
`;

const StyledContainer = styled.div`
  .Toastify__toast-container {
    position: fixed;
    z-index: 500;
}
  }
  .Toastify__toast {
    display: flex;
    align-items: center;
    height: 100%;
    background: ${white};
    border-radius: 20px;
    border: 1px solid ${black};
    height: 50px;
    min-width: 200px;
    left: 2rem;
    top: 2rem;
    position: relative;
    padding: 1rem 1.5rem;
    margin-bottom: 1rem;
  }
  .Toastify__close--button {
  }
  .Toastify__toast--error {
  }
  .Toastify__toast--warning {
  }
  .Toastify__toast--success {
  }
  .Toastify__toast-body {
  }
  .Toastify__progress-bar {
    transition: all 1s;
    animation: ${Toastify__trackProgress} linear 1;
  }
`;

export const PageLayout = ({ children, path, location }) => {
  const infoPages = ['imprint', 'privacy-policy', '404'];
  const contentPages = [
    '/',
    '/services/',
    '/references/',
    '/prices/',
    '/faq/',
    '/contact/',
  ];

  const isHome = path === '/';
  const isContent = contentPages.includes(path);
  const isInfoPage = infoPages.some(page => path.includes(page));

  return (
    <LayoutContainer>
      <Header />
      <StyledContainer>
        <ToastContainer
          closeButton={false}
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          draggable
          pauseOnHover
        />
      </StyledContainer>
      {!isInfoPage && (
        <ElementTransition>
          <Box mx={{ xs: 3, sm: 0 }}>
            <StaticQuery
              query={graphql`
                query {
                  desktop: file(relativePath: { eq: "main-bg.jpg" }) {
                    childImageSharp {
                      fluid(quality: 90, maxWidth: 1920) {
                        ...GatsbyImageSharpFluid_withWebp
                      }
                    }
                  }
                }
              `}
              render={data => {
                const image = data.desktop.childImageSharp.fluid;
                return (
                  <BackgroundImage Tag="div" fluid={image}>
                    <DesktopIntro isHome={isHome} image={image} />
                  </BackgroundImage>
                );
              }}
            />
          </Box>
        </ElementTransition>
      )}
      {(isContent || isInfoPage) && (
        <>
          <ContentWrapper>
            {isHome && (
              <TextWrapperMobile>
                <ElementTransition>
                  <IntroText fullHeight>
                    <IntroTextContent />
                  </IntroText>
                </ElementTransition>
              </TextWrapperMobile>
            )}
            <PageTransition location={location}>{children}</PageTransition>
          </ContentWrapper>
          <Footer />
        </>
      )}
    </LayoutContainer>
  );
};

const IntroTextContent = () => (
  <>
    <p>WE ALL</p>
    <p>DO IT</p>
    <p>BECAUSE</p>
    <p>
      WE{' '}
      <ElementTransition
        delay={1800}
        duration={500}
        animation={emphasize}
        defaultStyles={{
          color: salmon,
          display: 'inline-block',
        }}
      >
        LOVE
      </ElementTransition>
    </p>
    <p>MUSIC.</p>
  </>
);

const DesktopIntro = ({ isHome }) => {
  const [showIntro, setShowIntro] = useState(false);

  const showIntroText = showIntro && isHome;

  const shouldShowIntroText = () => {
    const shouldShow = window.scrollY < 15;
    if (shouldShow) setShowIntro(true);
    else setShowIntro(false);
  };

  useEffect(() => {
    if (isHome && typeof window !== 'undefined') {
      shouldShowIntroText();

      window.addEventListener('scroll', shouldShowIntroText);
      return () => {
        window.removeEventListener('scroll', shouldShowIntroText);
      };
    }
  }, []);

  return (
    <ImgCarouselSpacer fullHeight={showIntroText}>
      {isHome && (
        <TextWrapperDesktop>
          <ElementTransition>
            <IntroText fullHeight={showIntroText}>
              <IntroTextContent />
            </IntroText>
          </ElementTransition>
        </TextWrapperDesktop>
      )}
    </ImgCarouselSpacer>
  );
};
