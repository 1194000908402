import React from 'react';
import styled from 'styled-components/macro';
import { Box } from '@material-ui/core';

const Wrapper = styled(Box)`
  flex-grow: 1;
  height: 100%;
`;

export const Content = ({ children, ...props }) => (
  <Box px={1} {...props}>
    {children}
  </Box>
);

export const ContentBlock = ({ children, ...props }) => (
  <Box py={3} {...props}>
    {children}
  </Box>
);

export const ContentElement = ({ children, ...props }) => (
  <Wrapper {...props}>{children}</Wrapper>
);

export const InfoPageContainer = ({ children }) => (
  <Box mt={20}>{children}</Box>
);
