/* eslint-disable react/display-name */
const React = require('react');
const { PageLayout, RootLayout } = require('./src/components');

exports.wrapPageElement = ({ element, props }) => {
  return <PageLayout {...props}>{element}</PageLayout>;
};

exports.wrapRootElement = ({ element }) => {
  return <RootLayout>{element}</RootLayout>;
};

exports.shouldUpdateScroll = ({
  routerProps: { location },
  prevRouterProps,
}) => {
  if (prevRouterProps && location === prevRouterProps.location) {
    return false;
  }
  window.scrollTo({
    top: 0,
    left: 0,
    behavior: 'smooth',
  });
  return false;
};
