import React from 'react';
import styled, { css } from 'styled-components';

import { white, black } from '../utils/sharedStyles';

const MenuWrapper = styled.button`
  z-index: 4;
  border: none;
  background: none;

  &:hover {
    cursor: pointer;
  }
`;

const Bar = styled.div`
  width: 2.5rem;
  height: 3px;
  border-radius: 0.4rem;
  margin: 0.45rem 0;
  transition: 0.4s;
  background-color: ${black};
`;

const activeColor = css`
  background-color: ${props => (props.active ? white : black)};
`;

const UpperBar = styled(Bar)`
  transform: ${props =>
    props.active ? 'rotate(-45deg) translate(-0.5rem, 0.5rem)' : 'none'};
  ${activeColor}
`;

const MiddleBar = styled(Bar)`
  opacity: ${props => (props.active ? 0 : 1)};
`;

const LowerBar = styled(Bar)`
  transform: ${props =>
    props.active ? 'rotate(45deg) translate(-0.35rem, -0.4rem)' : 'none'};
  ${activeColor}
`;

export const BurgerMenu = ({ show, setShow }) => {
  return (
    <MenuWrapper
      title="Menu"
      aria-label="Navigation"
      aria-haspopup="true"
      onClick={() => setShow(!show)}
    >
      <UpperBar active={show} />
      <MiddleBar active={show} />
      <LowerBar active={show} />
    </MenuWrapper>
  );
};
