import React from 'react';
import smoothscroll from 'smoothscroll-polyfill';
import {
  StylesProvider,
  ThemeProvider,
  createMuiTheme,
} from '@material-ui/core/styles';

import { useViewHeight } from '../utils/helpers';
import { GlobalStyle, white } from '../utils/sharedStyles';

const theme = createMuiTheme({
  overrides: {
    MuiGrid: {
      'spacing-xs-2': {
        padding: '0.5rem',
      },
    },
    MuiDivider: {
      root: {
        backgroundColor: white,
      },
    },
    MuiPaper: {
      elevation8: {
        boxShadow: '0px 0px 11px 1px rgb(0 0 0 / 30%)',
      },
    },
  },
});

export const RootLayout = ({ children }) => {
  useViewHeight();
  if (typeof window !== 'undefined') {
    smoothscroll.polyfill();
  }
  return (
    <StylesProvider injectFirst>
      <ThemeProvider theme={theme}>
        <GlobalStyle />
        {children}
      </ThemeProvider>
    </StylesProvider>
  );
};
