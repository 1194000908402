import React, { useLayoutEffect } from 'react';
import { Link } from 'gatsby';
import styled from 'styled-components/macro';

import { ElementTransition } from './Transitions';

import { white, black } from '../utils/sharedStyles';

import instaMobile from '../assets/svgs/main-insta-mobile.svg';
import facebookMobile from '../assets/svgs/main-facebook-mobile.svg';

const Overlay = styled.div`
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: ${black};
  position: fixed;
  opacity: 0.9;
  overflow-y: scroll;
  z-index: -1;
`;

const Fader = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  height: 160px;
  background: linear-gradient(${black} 75%, rgba(0, 0, 0, 0));
`;

const ContentSmallscreen = styled.div`
  position: static;
  display: flex;
  flex-direction: column;
  margin: 13rem 2rem;
  align-items: center;
  z-index: -1;
`;

const StyledLinkSmallscreen = styled(Link)`
  color: ${white};
  text-decoration: none;
  font-size: 2.2rem;
`;

const SocialIconsContainer = styled.div`
  display: flex;
  margin-top: 1rem;
  justify-content: center;
  border-top: 1px solid ${white};
`;

const SocialIconsSmallscreen = styled.img`
  width: 25px;
  height: 25px;
  margin: 2.5rem 2rem;
`;

const useLockBodyScroll = () => {
  useLayoutEffect(() => {
    const originalStyle = window.getComputedStyle(document.documentElement)
      .overflow;
    document.documentElement.style.overflow = 'hidden';
    return () => (document.documentElement.style.overflow = originalStyle);
  }, []);
};

export const MenuSmallscreen = ({ pages, show, setShow }) => {
  useLockBodyScroll();
  return (
    <Overlay>
      <Fader />
      <ContentSmallscreen>
        {pages.map((page, index) => (
          <ElementTransition
            key={index}
            delay={100 * index}
            defaultStyles={{ margin: '1rem 0' }}
          >
            <StyledLinkSmallscreen
              id="el"
              to={page.to}
              onClick={() => setShow(!show)}
            >
              {page.name}
            </StyledLinkSmallscreen>
          </ElementTransition>
        ))}
        <SocialIconsContainer>
          <a href="https://www.instagram.com" target="_blank" rel="noreferrer">
            <SocialIconsSmallscreen src={instaMobile} />
          </a>
        </SocialIconsContainer>
      </ContentSmallscreen>
    </Overlay>
  );
};
