import React, { useEffect, useState } from 'react';
import { Link } from 'gatsby';
import styled, { css } from 'styled-components/macro';

import { BurgerMenu } from './BurgerMenu';
import { MenuSmallscreen } from './MenuSmallscreen';
import { Meta } from './Meta';

import logo from '../assets/svgs/main-ekout-logo.svg';
import instagram from '../assets/svgs/main-insta-standard.svg';
import facebook from '../assets/svgs/main-facebook-standard.svg';
import { white, black, headerHeight } from '../utils/sharedStyles';

const mobileMedia = '(max-width: 1000px)';

const baseLinkStyles = css`
  margin: 0.3rem 1rem;
  text-decoration: none;
  border-bottom: 1px solid transparent;
  padding-bottom: 0;
  transition: all 0.2s;

  &:hover {
    border-bottom: 1px solid ${black};
    padding-bottom: 1px;
  }
`;

const activeStyle = {
  borderBottom: `1px solid ${black}`,
};

const HeaderContainer = styled.div`
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: ${headerHeight}px;
  width: 100vw;
  background-color: ${white};
  z-index: 1;
  font: normal normal normal 16px/22px Ibarra Real Nova;
`;

const Content = styled.div`
  display: flex;
  position: relative;
  justify-content: flex-end;
  align-items: center;
  width: 100%;
  margin: 0 2rem;
  max-width: 1500px;
`;

const StyledLink = styled(Link)`
  color: ${black};
  font-size: 22px;
  ${baseLinkStyles}
`;

const Logo = styled.img`
  position: absolute;
  width: 160px;
  height: 160px;
  left: 0;
  top: 0;
  transform: translateX(0);
  z-index: 3;

  @media ${mobileMedia} {
    transform: translateX(-50%);
    left: 50%;
  }
`;

const SocialIcon = styled.img`
  width: 16px;
  height: 16px;
`;

const ExternalLink = styled.a`
  ${baseLinkStyles}
`;

const MobileMenuWrapper = styled.div`
  display: none;

  @media ${mobileMedia} {
    display: block;
  }
`;

const MenuWrapper = styled.div`
  @media ${mobileMedia} {
    display: none;
  }
`;

const pages = [
  { to: '/', name: 'The Studio' },
  { to: '/services', name: 'Services' },
  { to: '/references', name: 'References' },
  { to: '/prices', name: 'Prices' },
  { to: '/faq', name: 'FAQ' },
  { to: '/contact', name: 'Contact' },
];

export const Header = () => {
  const [show, setShow] = useState(false);

  const onKeyDown = e => {
    if (e.keyCode === 27) setShow(false);
  };

  useEffect(() => {
    document.addEventListener('keydown', onKeyDown, false);
    return () => document.removeEventListener('keydown', onKeyDown, false);
  }, []);

  return (
    <HeaderContainer>
      <Meta />
      <Content>
        <Link to="/" onClick={() => setShow(false)}>
          <Logo alt="logo" src={logo} />
        </Link>
        <MobileMenuWrapper>
          <BurgerMenu show={show} setShow={setShow} />
          {show && (
            <MenuSmallscreen setShow={setShow} show={show} pages={pages} />
          )}
        </MobileMenuWrapper>
        <MenuWrapper>
          {pages.map((page, index) => (
            <StyledLink key={index} activeStyle={activeStyle} to={page.to}>
              {page.name}
            </StyledLink>
          ))}
          <ExternalLink
            href="https://www.instagram.com/ekoutstudio"
            target="_blank"
            rel="noreferrer"
          >
            <SocialIcon src={instagram} />
          </ExternalLink>
        </MenuWrapper>
      </Content>
    </HeaderContainer>
  );
};
