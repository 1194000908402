import { createGlobalStyle, css } from 'styled-components/macro';

export const salmon = '#ff997c';
export const lightSalmon = '#f6ddd1';
export const white = '#fffff7';
export const beige = '#fffff8';
export const lightBlack = '#6f6965';
export const black = '#4d4642';
export const darkGrey = '#C3C1C0';
export const mediumGrey = '#E6E3DC';
export const lightGrey = '#F5F4ED';

export const contentWidthDesktop = '1100px';
export const headerHeight = 100;
export const border = '1px solid #0000001C;';
export const transition = 'transition: all 0.4s ease-out;';
export const borderRadius = 'border-radius: 3px;';
export const borderRadiusTop = css`
  border-top-left-radius: 3px;
  border-top-right-radius: 3px;
`;
export const borderRadiusBottom = css`
  border-bottom-left-radius: 3px;
  border-bottom-right-radius: 3px;
`;

export const mediaMobile = '(max-width: 850px)';
export const mediaDesktop = '(min-width: 851px)';

export const GlobalStyle = createGlobalStyle`
  * {
    margin: 0;
    padding: 0;
    outline: none;
    box-sizing: border-box;
    max-width: 100vw;
  }
  
  html {
    font-family: Ibarra Real Nova;
    color: ${black};
    background-color: ${white};
  }
`;
